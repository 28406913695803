<template>
  <div class="game-wrapper game">
    <div class="game-inner">
      <v-card-text>
        <div
          class="ms-0 align-start"
        >
          <v-row class="px-1 py-1">
            <v-col
              cols="12"
              class="py-1 px-3 d-flex justify-space-between align-center"
            >
              <div class="d-flex">
                <h1 class="text-h6 font-weight-bold black--text">
                  {{ t('game.pay_my_part') }}
                </h1>
              </div>

              <div>
                <v-btn
                  v-if="!privateLanding"
                  class="ma-0 py-2 px-0 no-bg-hover font-weight-bold text-body-1"
                  style="
                    text-transform: inherit;
                    min-width: 60px;
                  "
                  text
                  color="secondary"
                  @click="onLogin()"
                >
                  {{ t('login.login') }}
                </v-btn>

                <app-bar-user-avatar
                  v-else
                  class="ms-2"
                  :show-question="true"
                  :title="$t('reservations.you_are_leaving')"
                  :confirm-button-text="$t('player_actions.leave')"
                  @logout="onLogout"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-text v-if="dataParams">
        <v-card-title class="text-body-1 font-weight-bold black--text px-0">
          <span>1. {{ $t('game.review_details') }}</span>
        </v-card-title>

        <booking-card
          :title="dataParams.sport_name"
          :date="`${formatStdDate(dataParams.reservation_date, 'date', true, $i18n.locale)} ${formatStdDate(dataParams.start_time, 'time', true, $i18n.locale)} - ${formatStdDate(dataParams.end_time, 'time', true, $i18n.locale)}`"
          :facility-name="dataParams.facility_name"
          :field-name="dataParams.field_name"
          :total="dataParams.total_with_fanaty_fee"
          :subtotal="dataParams.total"
          :fee-taxes="dataParams.fanaty_fee"
        ></booking-card>
      </v-card-text>

      <v-card-text v-if="dataParams">
        <policy-card
          :title="$t('game.cancellation_policy')"
          :subtitle="`${$t('game.cancellation_policy_msg')} ${formatStdDate(dataParams.cancelation_deadline, 'monthtime', true, $i18n.locale)}`"
        >
        </policy-card>
      </v-card-text>

      <v-card-text
        v-if="hasLastPaymentMethod"
        class="pt-0"
        :style="'padding-bottom: 150px;'"
      >
        <v-card-title class="text-body-1 font-weight-bold black--text px-0 pt-0">
          2. {{ $t('payment_methods.payment_method') }}
        </v-card-title>

        <payment-method-card
          :title="$t('payment_methods.payment_method')"
          :card-number="`${dataParams.last_payment_method.brand} ${dataParams.last_payment_method.last4}`"
          :card-date="`Exp. ${dataParams.last_payment_method.exp_month}/${dataParams.last_payment_method.exp_year}`"
          @changePaymentMethod="onChangePaymentMethod"
        >
        </payment-method-card>
      </v-card-text>

      <v-card-actions
        class="pay-card-action py-3 d-flex- flex-column"
      >
        <v-btn
          v-if="!!userLogged && hasLastPaymentMethod && !showChangePaymentMethod"
          class="text-body-1 font-weight-bold mb-2"
          style="border-radius: 33px; text-transform: initial;"
          color="primary"
          rounded
          large
          block
          @click="onContinueAndPay"
        >
          {{ t('reservations.confirm_and_pay') }}
        </v-btn>

        <transition name="fade">
          <div
            v-show="showChangePaymentMethod"
            class="w-100"
          >
            <!-- v-if="!userLogged || (!!userLogged && dataParams && dataParams.is_organizer)" -->
            <!-- class="pay-card-action py-3 d-flex- flex-column" -->
            <!-- style="visibility: hidden;" -->
            <div>
              <div class="d-flex mb-2">
                <span class="text-body-1 font-weight-bold">{{ $t('game.pay_with') }}</span>
                <v-spacer />
                <v-btn
                  v-if="hasLastPaymentMethod"
                  class="close-btn"
                  icon
                  small
                  style="background-color: #f2f2f2"
                  @click="showChangePaymentMethod = false"
                >
                  <v-icon color="#3f3f3f">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </div>

              <div
                id="express-checkout-element"
              ></div>

              <v-btn
                class="text-body-1 font-weight-bold my-2"
                style="border-radius: 33px; text-transform: initial;"
                color="primary"
                rounded
                large
                block
                :outlined="!onlyNewCard"
                @click="openUpdatePay(true)"
              >
                <span>{{ $t('pay_video.credit_card_new') }}</span>
              </v-btn>
            </div>

            <!-- <div
              v-show="containarLoading"
              class="py-3 justify-center align-center"
              :class="{'d-flex': containarLoading}"
              style="min-height: 150px;"
            >
              <div
                id="loader"
                class="loader"
              >
              </div>
            </div> -->
          </div>
        </transition>
      </v-card-actions>

      <checkout-pay
        v-if="dataParams"
        :is-open.sync="isDialogPay"
        :taxes="dataParams.fanaty_fee ? dataParams.fanaty_fee : 0"
        :subtotal="dataParams.total"
        :total="dataParams.total_with_fanaty_fee"
        :total-format="'usd'"
        :payment-methods="paymentMethods"
        :has-user="false"
        :user-data="!!userData ? userData : false"
        @openClose="openUpdatePay"
        @save="onPay"
      />
    </div>
  </div>
</template>

<script>
import {
  mdiCreditCardOutline, mdiFoodAppleOutline, mdiCalendar, mdiClose,
} from '@mdi/js'
import {
  ref, computed, onMounted,
} from '@vue/composition-api'
import { required, minLengthValidator, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { useRouter, formatCurrency, formatStdDate } from '@core/utils'
import themeConfig from '@themeConfig'
import useCryptoJs from '@core/utils/useCryptoJs'
import usePhone from '@core/utils/usePhone'
import useLogData from '@core/utils/useLogData'
import {
  getMatchUserCheckout, getMatchGameCode, getLandingUserPaymentMethods, addMatchUserPay,
} from '@api'
import { loadStripe } from '@stripe/stripe-js'

import AppBarUserAvatar from '@core/layouts/components/app-bar/AppBarUserAvatar.vue'
import BookingCard from '@/views/pay-booking/components/booking-card/BookingCard.vue'
import PolicyCard from '@/views/pay-booking/components/policy-card/PolicyCard.vue'
import PaymentMethodCard from '@/views/pay-booking/components/payment-method-card/PaymentMethodCard.vue'
import CheckoutPay from '@/views/video-checkout/components/CheckoutPay.vue'

export default {
  components: {
    AppBarUserAvatar,
    BookingCard,
    PolicyCard,
    PaymentMethodCard,
    CheckoutPay,
  },
  setup() {
    const { t } = useUtils()
    const { userData, encrypt, decryptData } = useCryptoJs()
    const { phoneCodeOptions } = usePhone()
    const { route, router } = useRouter()
    const { logData, updateLogData } = useLogData()

    const privateLanding = ref(null)
    const dataParams = ref(null)
    const slotSelected = ref(null)
    const isDialogPay = ref(false)
    const matchCode = ref(null)
    const paymentMethods = ref([])
    const userLogged = ref(null)

    // remove team
    const teamSelected = ref(null)

    const containarLoading = ref(true)
    const showChangePaymentMethod = ref(false)
    const isLoading = ref(false)
    const isInitialized = ref(false)
    const onlyNewCard = ref(true)
    let stripe
    let elements
    let expressCheckoutElement
    const hasLastPaymentMethod = computed(() => dataParams.value && dataParams.value.last_payment_method)

    const openUpdatePay = async val => {
      isDialogPay.value = val
    }

    const onLogin = async () => {
      logData.value.joinGame = 'NO'
      logData.value.matchCode = matchCode.value
      logData.value.redirect = 'landing'
      logData.value.field = 'all'
      logData.value.date = 'all'
      logData.value.time = 'all'

      // remove team
      logData.value.team = teamSelected.value
      await updateLogData(logData.value)

      router.push({ name: 'auth-login-phone-email' })
    }

    const onLogout = async () => {
      await localStorage.removeItem('userData')
      paymentMethods.value = []
      privateLanding.value = false
    }

    const payOrderVideo = async body => {
      const encryptInfo = encrypt(body)
      const respPay = await addMatchUserPay(matchCode.value, { info: encryptInfo })
      if (respPay.ok) {
        // remove team
        router.replace({
          name: userData.value.avatar === null || userData.value.avatar.includes('no-profile-pic') ? 'profile-picture' : 'match',
          params: {
            code: matchCode.value,
            joined: true,
            joinedMessage: respPay.data.notify_new_slot,
            joinedFrom: 'pay-booking',
            team: teamSelected.value,
          },
        })
      }
    }

    const onPay = async cardInfo => {
      const body = {
        slot: slotSelected.value,
        amount: dataParams.value.total_with_fanaty_fee,
        payment_method_id: cardInfo.payment_method_id,
        card_info: cardInfo,
      }
      await payOrderVideo(body)
    }

    const onContinue = async () => {
      if (userLogged.value) {
        openUpdatePay(true)
      } else {
        onLogin(true)
      }
    }

    const onContinueAndPay = async () => {
      const cardInfo = {
        payment_method_id: dataParams.value.last_payment_method.payment_method_id,
        save_card: null,
        name: null,
        number: null,
        exp_month: null,
        exp_year: null,
        cvc: null,
        country: null,
        zip_code: null,
        email: null,
      }
      await onPay(cardInfo)
    }

    const closeContainarLoading = () => {
      setTimeout(() => {
        // const container = document.getElementById('express-checkout-element').parentNode
        // container.classList.add('pay-card-action', 'py-3', 'px-4')
        setTimeout(() => {
          containarLoading.value = false
        }, 600)
      }, 1000)
    }

    const initStripe = async () => {
      try {
        const expressContent = document.getElementById('express-checkout-element')
        const centAmount = parseInt((dataParams.value.total_with_fanaty_fee * 100) || 1000, 10)
        if (expressContent) {
          const publishableKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY
          stripe = await loadStripe(publishableKey)

          const paymentRequest = stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
              label: 'Demo total',
              amount: centAmount,
            },
            requestPayerName: true,
            requestPayerEmail: true,
          })

          paymentRequest.canMakePayment().then(result => {
            if (result) {
              const appearance = {
                variables: {
                  borderRadius: '33px',
                },
                rules: {
                  '.Input, .Block': {
                    backgroundColor: 'transparent',
                    border: '1px solid var(primary-shade)',
                  },
                },
              }
              elements = stripe.elements({
                mode: 'payment',
                currency: 'usd',
                amount: centAmount,
                appearance,
                paymentMethodCreation: 'manual',
              })

              expressCheckoutElement = elements.create('expressCheckout')
              expressCheckoutElement.mount('#express-checkout-element')
              onlyNewCard.value = false

              expressCheckoutElement.on('ready', ({ availablePaymentMethods }) => {
                if (availablePaymentMethods) {
                  // const container = document.getElementById('express-checkout-element').parentNode
                  // container.classList.add('pay-card-action', 'py-3', 'px-4')

                  closeContainarLoading()
                }
              })

              expressCheckoutElement.on('confirm', async () => {
                const elementsSubmit = await elements.submit()
                const { error: submitError } = elementsSubmit
                if (submitError) {
                  console.error(submitError)
                }

                stripe
                  .createPaymentMethod({
                    elements,
                    params: {
                      billing_details: {
                        name: '',
                      },
                    },
                  })
                  .then(async resultPaymentMethod => {
                    if (resultPaymentMethod.paymentMethod.id) {
                      const cardInfo = {
                        payment_method_id: resultPaymentMethod.paymentMethod.id,
                        save_card: null,
                        name: null,
                        number: null,
                        exp_month: null,
                        exp_year: null,
                        cvc: null,
                        country: null,
                        zip_code: null,
                        email: null,
                      }
                      await onPay(cardInfo)
                    }
                  })
              })
            } else {
              onlyNewCard.value = true
            }
            isLoading.value = false
            isInitialized.value = true

            closeContainarLoading()
          }).catch(e => {
            console.error({ e })
            isLoading.value = false
            isInitialized.value = true

            closeContainarLoading()
          })
        }
      } catch (e) {
        console.error({ e })

        closeContainarLoading()
      }
    }

    const initForm = async () => {
      userLogged.value = await JSON.parse(localStorage.getItem('userData'))
      const { code, selected, team } = route.value.params
      matchCode.value = code
      slotSelected.value = parseInt(selected || 0, 10)

      // remove team
      teamSelected.value = `${team}`.toUpperCase()

      if (!userLogged.value) router.replace({ name: 'match', params: { code: matchCode.value, selected: slotSelected.value, team: teamSelected.value } })

      let response = null
      if (matchCode.value && userData.value) {
        if (teamSelected.value) response = await getMatchUserCheckout(matchCode.value, teamSelected.value)
        else response = await getMatchUserCheckout(matchCode.value)

        if (response.ok) dataParams.value = response.data

        if (!dataParams.value.can_checkout) router.replace({ name: 'match', params: { code: matchCode.value, selected: slotSelected.value, team: teamSelected.value } })

        const resp = await getLandingUserPaymentMethods()
        let paymentMethodsParams = []
        const newPaymentMethod = {
          id: 0,
          last_4: null,
          exp_month: null,
          exp_year: null,
          brand: 'Add new card',
          exp_date_str: null,
        }
        if (resp.ok && resp.data) {
          paymentMethodsParams = decryptData(resp.data)
          if (paymentMethodsParams.legth) {
            const finded = paymentMethodsParams.find(e => e.id === 0)
            if (!finded) paymentMethodsParams.unshift(newPaymentMethod)
          } else paymentMethodsParams.unshift(newPaymentMethod)
        } else {
          paymentMethodsParams.unshift(newPaymentMethod)
        }
        paymentMethods.value = paymentMethodsParams

        privateLanding.value = true
      } else {
        response = await getMatchGameCode(matchCode.value)
        if (response.ok) dataParams.value = response.data
        privateLanding.value = false
      }

      setTimeout(async () => {
        if (!hasLastPaymentMethod.value) showChangePaymentMethod.value = true
        if (!isInitialized.value) await initStripe()
      }, 1000)
    }

    const onChangePaymentMethod = async () => {
      showChangePaymentMethod.value = true

      // && !isMozilla.value
      if (!isInitialized.value) await initStripe()
      else {
        closeContainarLoading()
      }
    }

    onMounted(async () => {
      await initForm()
    })

    return {
      privateLanding,
      dataParams,
      isDialogPay,
      matchCode,
      paymentMethods,
      userData,
      containarLoading,
      showChangePaymentMethod,
      userLogged,

      // computed
      phoneCodeOptions,

      // methods
      formatCurrency,
      onLogin,
      onLogout,
      openUpdatePay,
      payOrderVideo,
      onPay,
      formatStdDate,
      initStripe,
      isInitialized,
      isLoading,
      onlyNewCard,
      hasLastPaymentMethod,

      onContinue,
      onContinueAndPay,
      onChangePaymentMethod,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,

      // validations
      validators: {
        required,
        minLengthValidator,
        integerValidator,
      },

      t,

      icons: {
        mdiCreditCardOutline,
        mdiFoodAppleOutline,
        mdiCalendar,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/view.scss';
</style>

<style lang="scss" scoped>
// @import '@core/preset/preset/pages/landing.scss';
@import '@core/preset/preset/pages/game.scss';

.theme--dark.v-divider {
  border-color: #333333;
}

.bg-grey {
  background-color: #979797;
}
</style>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.v-input__append-inner {
  max-width: auto 0px;
}

.white-border {
  border: 2px solid #ffffff !important;
}

.app-content-container {
  padding: 0px !important;
}

.card-img {
  height: auto;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
</style>
